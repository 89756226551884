<template>
    <div class="p-grid">
        <div class="p-col-12">
            <Toast />

            <Fieldset :legend="$t('reports.addBlockedPlayer')" :toggleable="true" :collapsed="!showFieldset">
                <div class="p-grid">
                    <h6 class="p-col-12">{{ $t('reports.playerDetails') }}</h6>
                    <div class="p-col-12 p-md-2">
                        <span class="p-float-label">
                            <InputText style="width: 100%" type="text" v-model="player.FirstName" />
                            <label>{{ $t('system.firstName') }}</label>
                        </span>
                    </div>
                    <div class="p-col-12 p-md-2">
                        <span class="p-float-label">
                            <InputText style="width: 100%" type="text" v-model="player.LastName" />
                            <label>{{ $t('system.lastName') }}</label>
                        </span>
                    </div>
                    <div class="p-col-12 p-md-2">
                        <span class="p-float-label">
                            <InputText style="width: 100%" type="text" v-model="player.CardId" />
                            <label>{{ $t('reports.cardId') }}</label>
                        </span>
                    </div>
                    <div class="p-col-12 p-md-6 p-text-right">
                        <Button @click="addNewBlockedPlayer()" :disabled="disableSubmit" :label="$t('buttons.add')" class="p-button-raised p-button-success p-mr-2 p-mb-2" />
                    </div>
                </div>
            </Fieldset>
            <div class="p-grid p-mt-2">
                <div class="p-col-12 p-md-2">
                    <span class="p-float-label">
                        <InputText type="text" v-model="cardIdModel" @input="debounceSearch" />
                        <label>{{ $t('search.byCardId') }}</label>
                    </span>
                </div>
                <div class="p-col-6 p-md-9 p-text-right"><Button type="button" :loading="isReportLoading" :label="$t('buttons.show')" class="p-button-raised p-button-success" @click="showReport()" /></div>
                <div class="p-col-6 p-md-1 p-text-right">
                    <Button type="button" :label="$t('buttons.saveXls')" :loading="isReportDownloading" icon="pi pi-download" class="p-button-raised p-button-info" @click="saveXls()" />
                </div>
            </div>
            <div class="card" v-if="showReportData">
                <DataTable @page="onPage($event)" :paginator="true" :rows="perPage" :loading="isReportLoading" class="p-mt-3" ref="datatable" responsiveLayout="scroll" :paginatorBottom="true" :value="filteredPlayers">
                    <Column>
                        <template #body="slotProps">
                            <span class="indexColumn">{{ (this.crtPage - 1) * this.perPage + slotProps.index + 1 }}</span>
                        </template>
                    </Column>
                    <Column field="cardId" :header="$t('reports.cardId')" :sortable="true"></Column>
                    <Column field="firstName" :header="$t('system.firstName')" :sortable="true"> </Column>
                    <Column field="lastName" :header="$t('system.lastName')" :sortable="true"></Column>
                    <Column field="addedOn" :header="$t('transactions.addDate')" :sortable="true">
                        <template #body="{ data }"> {{ formatCSDateAndTime(data.addedOn) }} </template>
                    </Column>
                    <Column field="modifiedBy" :header="$t('transactions.addedBy')" :sortable="true"> </Column>
                    <Column :exportable="false" class="p-text-right">
                        <template #body="slotProps">
                            <Button icon="pi pi-times" class="p-button-rounded p-button-danger p-mr-2" @click="removeBlockedPlayer(slotProps.data.cardId)" />
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
    </div>
</template>

<script>
import { BoApi } from '../../../../service/BoApi';
import { BoSaveFilesApi } from '../../../../service/BoSaveFilesApi';
import { uiSettings } from '../../../../settings/uiSettings';

export default {
    name: 'BlockedPlayers',
    data() {
        return {
            reportData: [],
            BoSaveFilesApi: new BoSaveFilesApi(),
            BoApi: new BoApi(),
            isReportLoading: false,
            isReportDownloading: false,
            showReportData: false,
            perPage: uiSettings.tableRows,
            crtPage: 1,
            cardIdModel: '',
            player: {},
            showFieldset: false,
            term: '',
            disableSubmit: false,
            idCardNumber: '',
        };
    },
    computed: {
        filteredPlayers() {
            if (this.notNullOrEmptyField(this.term)) {
                return this.reportData.filter((player) => {
                    return player.cardId.indexOf(this.term) >= 0;
                });
            }
            return this.reportData;
        },
    },
    methods: {
        showReport() {
            this.reportData = [];
            this.showReportData = false;
            this.isReportLoading = true;
            this.BoApi.getBlockedPlayers()
                .then((response) => {
                    this.reportData = response.data;
                    this.isReportLoading = false;
                    this.showReportData = true;
                })
                .catch((error) => {
                    this.checkForUnauthorizedError(error);
                    this.showToast('error', this.$t('alerts.generalError'));
                    this.isReportLoading = false;
                });
        },
        saveXls() {
            this.isReportDownloading = true;
            this.BoSaveFilesApi.saveBlockedPlayers()
                .then((response) => {
                    this.isReportDownloading = false;
                    let blob = new Blob([response.data], { type: 'application/xls' });
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = 'BlockedPlayers.xlsx';
                    link.click();
                })
                .catch((error) => {
                    this.checkForUnauthorizedError(error);
                    this.showToast('error', this.$t('alerts.generalError'));
                    this.isReportDownloading = false;
                });
        },
        onPage(event) {
            this.crtPage = event.page + 1;
        },
        showToast(toastSeverity, message) {
            this.$toast.add({ severity: toastSeverity, summary: '', detail: message, life: this.getToastLifeSeconds() });
        },
        removeBlockedPlayer(cardId) {
            this.BoApi.removeBlokedPlayer(cardId)
                .then(() => {
                    this.showReport();
                })
                .catch((error) => {
                    this.checkUnauthorizedError(error);
                    this.showToast('error', this.$t('alerts.generalError'));
                });
        },
        addNewBlockedPlayer() {
            this.disableSubmit = true;
            if (this.notNullOrEmptyObject(this.player) && this.notNullOrEmptyField(this.player.LastName) && this.notNullOrEmptyField(this.player.FirstName) && this.notNullOrEmptyField(this.player.CardId)) {
                if (this.validateCNP(this.player.CardId)) {
                    this.BoApi.addBlockedPlayer(this.player)
                        .then(() => {
                            this.showFieldset = false;
                            this.player = {};
                            this.showReport();
                        })
                        .catch((error) => {
                            this.checkUnauthorizedError(error);
                            this.showToast('error', this.$t('alerts.generalError'));
                        });
                } else {
                    this.showToast('error', this.$t('system.alerts.invalidCNP'));
                }
            } else {
                this.showToast('error', this.$t('alerts.mandatoryFieldsAll'));
            }
            this.disableSubmit = false;
        },
        debounceSearch(event) {
            clearTimeout(this.debounce);
            this.debounce = setTimeout(() => {
                this.term = event.target.value;
            }, this.debounceTime);
        },
    },
};
</script>