<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <h5>{{ $t('menu.winsById') }}</h5>
                <TabView>
                    <TabPanel :header="$t('reports.winsById')">
                        <WinsById></WinsById>
                    </TabPanel>
                    <TabPanel :header="$t('reports.blockedPlayers')">
                        <BlockedPlayers></BlockedPlayers>
                    </TabPanel>
                </TabView>
            </div>
        </div>
    </div>
</template>
<script>
import WinsById from './Components/WinsById';
import BlockedPlayers from './Components/BlockedPlayers';

export default {
    name: 'PlayersWins',
    components: {
        WinsById,
        BlockedPlayers,
    },
};
</script>
