<template>
    <div class="p-grid">
        <div class="p-col-12">
            <Toast />
            <div class="p-grid">
                <div class="p-col-12 p-md-2">
                    <span class="p-float-label">
                        <InputText type="text" v-model="cardIdModel" />
                        <label>{{ $t('bets.winnerId') }}</label>
                    </span>
                </div>
                <div class="p-col-6 p-md-9 p-text-right"><Button type="button" :loading="isReportLoading" :label="$t('buttons.show')" class="p-button-raised p-button-success" @click="showReport()" /></div>
                <div class="p-col-6 p-md-1 p-text-right">
                    <Button type="button" :label="$t('buttons.saveXls')" :loading="isReportDownloading" icon="pi pi-download" class="p-button-raised p-button-info" @click="saveXls()" />
                </div>
            </div>
            <div class="card" v-if="showReportData">
                <DataTable @page="onPage($event)" :paginator="true" :rows="perPage" :loading="loading" class="p-mt-3" ref="datatable" responsiveLayout="scroll" :paginatorBottom="true" :value="reportData">
                    <Column>
                        <template #body="slotProps">
                            <span class="indexColumn">{{ (this.crtPage - 1) * this.perPage + slotProps.index + 1 }}</span>
                        </template>
                    </Column>
                    <Column field="ticketSerial" :header="$t('bets.ticketSerial')" :sortable="true"></Column>
                    <Column field="payDate" :header="$t('bets.payDate')" :sortable="true">
                        <template #body="{ data }"> {{ formatCSDateAndTime(data.payDate) }} </template>
                    </Column>
                    <Column field="cashInShop" :header="$t('bets.payShop')" :sortable="true">
                        <template #body="{ data }"> {{ data.cashInShop.name }} </template></Column
                    >
                    <Column field="winAmount" :header="$t('bets.winAmount')" :sortable="true">
                        <template #body="{ data }"> {{ formatCurrency(data.winAmount) }} </template>
                    </Column>
                    <Column field="winTaxAmount" :header="$t('bets.winTaxAmount')" :sortable="true">
                        <template #body="{ data }"> {{ formatCurrency(data.winTax) }} </template>
                    </Column>
                    <Column :header="$t('bets.totalWin')" :sortable="true">
                        <template #body="{ data }"> {{ formatCurrency(data.winTax + data.winAmount) }} </template>
                    </Column>
                </DataTable>
            </div>
        </div>
    </div>
</template>

<script>
import { BoReportsApi } from '../../../../service/BoReportsApi';
import { BoSaveReportsApi } from '../../../../service/BoSaveReportsApi';
import { uiSettings } from '../../../../settings/uiSettings';

export default {
    name: 'WinsById',
    data() {
        return {
            reportData: [],
            BoReportsApi: new BoReportsApi(),
            BoSaveReportsApi: new BoSaveReportsApi(),
            isReportLoading: false,
            isReportDownloading: false,
            showReportData: false,
            perPage: uiSettings.tableRows,
            crtPage: 1,
            cardIdModel: '',
        };
    },
    methods: {
        showReport() {
            if (this.notNullOrEmptyField(this.cardIdModel)) {
                this.showReportData = false;
                this.isReportLoading = true;
                this.BoReportsApi.getWinsByID(this.cardIdModel)
                    .then((response) => {
                        this.reportData = response.data;
                        this.isReportLoading = false;
                        this.showReportData = true;
                    })
                    .catch((error) => {
                        this.checkForUnauthorizedError(error);
                        this.showToast('error', this.$t('alerts.generalError'));
                        this.isReportLoading = false;
                    });
            } else {
                this.showToast('error', this.$t('alerts.completeField', { field: 'winner id' }));
            }
        },
        saveXls() {
            this.isReportDownloading = true;
            this.BoSaveReportsApi.saveWinsById(this.cardIdModel)
                .then((response) => {
                    this.isReportDownloading = false;
                    let blob = new Blob([response.data], { type: 'application/xls' });
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = 'WinsById.xlsx';
                    link.click();
                })
                .catch((error) => {
                    this.checkForUnauthorizedError(error);
                    this.showToast('error', this.$t('alerts.generalError'));
                    this.isReportDownloading = false;
                });
        },
        onPage(event) {
            this.crtPage = event.page + 1;
        },
        showToast(toastSeverity, message) {
            this.$toast.add({ severity: toastSeverity, summary: '', detail: message, life: this.getToastLifeSeconds() });
        },
    },
};
</script>