import axios from 'axios'
import settings from '../settings/apis.js'
import {store} from '../store/index.js'

class BoSaveFilesApi {
    constructor() {
        this.Api = axios.create({
            baseURL: settings[process.env.NODE_ENV].boApiUrl,
            withCredentials: false,

            headers: {
                'Content-Type': 'application/json',
                'X-Access-Token': store.getters['user/accessToken']
            }
        });
    }
    saveBlockedPlayers() {
        return this.Api.get('/players/getBlockedPlayersXls', {responseType: 'blob'});
    }
    getVouchersXls(startDate, endDate, shopId, status) {
        return this.Api.get('/bettingOffice/getVouchersXls?startDate='+startDate+'&endDate='
        +endDate+'&status='+status+'&shop='+shopId,{responseType: 'blob'});
    }
}

export {
    BoSaveFilesApi
}
